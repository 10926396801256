$(function() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    keyword = urlParams.get('hl');
    if (keyword === null){
      keyword = urlParams.get('q[search_context_cont]')
    }

    if (keyword !== null){
        $("body").mark(keyword, {});
        $('.table').on('load-success.bs.table, post-body.bs.table', function (e, name, args) {
            $("body").mark(keyword, {});
        })
    }
});
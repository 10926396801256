const printBtn = document.getElementById('print-btn');
if (printBtn) {
  printBtn.addEventListener('click', printArea);
}

function printArea() {
  const printContent = document.getElementById('print-area');
  const originalContent = document.body.innerHTML;

  document.body.innerHTML = printContent.innerHTML;
  window.print();

  document.body.innerHTML = originalContent;
}
